import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from "@blueprintjs/core";
import {navigate} from "gatsby";
import {Overlay, Icon, Classes} from '@blueprintjs/core';
import {amber} from "material-colors";

// Content components
import MainLayout from '../../components/main-layout';

import biuwerForEcommerceMainImg from "../../../static/img/biuwer-for-ecommerce-woocommerce-analytics.png";
import biuwerForEcommerceHowWorksImgEs from "../../../static/img/biuwer-for-ecommerce-como-funciona.png";
import biuwerForEcommerceHowWorksImgEn from "../../../static/img/biuwer-for-ecommerce-como-funciona.png";
import onlineShopsLogosImg from "../../../static/img/online-shops-logos.png";
import FaqItem from "../Pricing/faq-item";

const INDIGO_HEX_COLOR = '#012E67';

const EcommerceSolution = (props) => {

    // Video overlay opened control
    const [overlayOpened, setOverlayOpened] = useState(false);

    const { t, i18n } = useTranslation(),
        { pageContext } = props;

    const styles = {
        imgStyle: {
            width: '100%',
            boxShadow: '0px 10px 30px rgb(0 0 0 / 5%), 0px 20px 60px rgb(0 0 0 / 10%)'
        },
        textStyle: {
            color: '#666'
        },
        listContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        },
        iconContainer: {
            backgroundColor: '#fff',
            boxShadow: '0 4px 10px 2px #eaeaea',
            borderRadius: '50%',
            minWidth: 50,
            width: 50,
            height: 50,
            marginRight: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        icon: {
            fontSize: 28,
            color: amber[500]
        },
        bigNumber: {
            width: 40,
            color: amber[500],
            fontSize: 30,
            fontWeight: 'bold',
            marginRight: 10,
            textAlign: 'center'
        },
        bigNumberTitle: {
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: '45px'
        }
    };

    return (
        <MainLayout pageContext={pageContext}>
            {/* Header Section */}
            <div className="container pt40" style={{ marginTop: 72 }}>
                <div className="row mv40">
                    <div className="col-sm-12 col-lg-5 mb20">
                        <h1 className="mb20">{t('solutions:ecommerce.header.title')}</h1>
                        <h5 className="mb40" style={{ fontWeight: 400 }}>{t('solutions:ecommerce.header.subtitle')}</h5>
                        <div className="mb20">
                            <Button
                                text={t("mainMenuLinks.requestDemo").toUpperCase()}
                                intent="primary"
                                style={{ boxShadow: 'none', marginRight: 20 }}
                                large={true}
                                onClick={() => i18n.language === 'en' ? navigate('/en/book-demo') : navigate('/es/reserva-demo')}
                            />
                            <a
                                href={`https://app.biuwer.com/signup?language=${i18n.language || 'en'}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-success"
                                style={{ color: 'white', textDecoration: 'none', height: 40 }}
                            >
                                {t("mainMenuLinks.tryForFree").toUpperCase()}
                            </a>
                        </div>
                        <div className="pv20">
                            <img src={onlineShopsLogosImg}
                                 width={300}
                                 alt={t('siteTitle')}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-7 mb20">
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute' }}>
                                <img style={styles.imgStyle}
                                     src={biuwerForEcommerceMainImg}
                                     alt={t('siteTitle')}
                                />
                            </div>
                            <div style={{ position: 'absolute', width: '100%', height: 400 }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Button
                                        text={t("solutions:ecommerce.header.quickView")}
                                        large={true}
                                        minimal={true}
                                        icon={
                                            <Icon icon={'play'} size={24} style={{ color: 'white' }} />
                                        }
                                        style={{
                                            backgroundColor: INDIGO_HEX_COLOR,
                                            color: 'white',
                                            boxShadow: 'none',
                                            padding: 24,
                                            fontSize: 24,
                                            borderRadius: 30
                                        }}
                                        onClick={() => setOverlayOpened(true)}
                                    />
                                </div>
                            </div>
                            <Overlay
                                isOpen={overlayOpened}
                                onClose={() => setOverlayOpened(false)}
                                className={Classes.OVERLAY_SCROLL_CONTAINER}
                            >
                                <div style={{ width: "100%", height: "100%" }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%" }}>
                                        <iframe
                                            width="80%"
                                            height="80%"
                                            src="https://www.youtube.com/embed/URaE84BDuLA"
                                            title="Biuwer for Ecommerce"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            webkitallowfullscreen="true"
                                            mozallowfullscreen="true"
                                            allowFullScreen
                                        />
                                    </div>
                                    <div style={{ position: 'absolute', top: 20, right: 20 }}>
                                        <Icon icon={'cross'} size={24} style={{ color: 'white' }} onClick={() => setOverlayOpened(false)} />
                                    </div>
                                </div>
                            </Overlay>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why Biuwer for Ecommerce Section */}
            <section className="pv40" style={{ backgroundColor: '#F9FBFD' }}>
                <div className="container" style={styles.section}>
                    <div className="row mb30">
                        <div className="col-12" style={{ textAlign: 'center' }}>
                            <h2 className="mb20">{t('solutions:ecommerce.whatFor.title')}</h2>
                            <h5 style={{ fontWeight: 400 }}>{t('solutions:ecommerce.whatFor.subtitle')}</h5>
                        </div>
                    </div>
                    <div className="row mb30">
                        <div className="col-12" style={{ textAlign: 'center' }}>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-chart bp3-icon-large" style={styles.icon}/>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h4 style={{ margin: 0 }}>{t('solutions:ecommerce.whatFor.rightTool').toUpperCase()}</h4>
                                </div>
                            </div>
                            <div>
                                <h6>{t('solutions:ecommerce.whatFor.rightToolQ1')}</h6>
                                {/*<p>{t('solutions:ecommerce.whatFor.rightToolA1')}</p>*/}
                                {/*<p>{t('solutions:ecommerce.whatFor.rightToolA1Bis')}</p>*/}
                            </div>
                            <div>
                                <h6>{t('solutions:ecommerce.whatFor.rightToolQ2')}</h6>
                                {/*<p>{t('solutions:ecommerce.whatFor.rightToolA2')}</p>*/}
                                {/*<p>{t('solutions:ecommerce.whatFor.rightToolA2Bis')}</p>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row mb30">
                        <div className="col-12" style={{ textAlign: 'center' }}>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-time bp3-icon-large" style={styles.icon}/>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h4 style={{ margin: 0 }}>{t('solutions:ecommerce.whatFor.saveTime').toUpperCase()}</h4>
                                </div>
                            </div>
                            <div>
                                <h6>{t('solutions:ecommerce.whatFor.saveTimeQ1')}</h6>
                                {/*<p>{t('solutions:ecommerce.whatFor.saveTimeA1')}</p>*/}
                                <h6>{t('solutions:ecommerce.whatFor.saveTimeQ2')}</h6>
                                <h6>{t('solutions:ecommerce.whatFor.saveTimeQ3')}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mb30">
                        <div className="col-12" style={{ textAlign: 'center' }}>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-lock bp3-icon-large" style={styles.icon}/>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h4 style={{ margin: 0 }}>{t('solutions:ecommerce.whatFor.dataOwnership').toUpperCase()}</h4>
                                </div>
                            </div>
                            <div>
                                <h6>{t('solutions:ecommerce.whatFor.dataOwnershipQ1')}</h6>
                                {/*<p>{t('solutions:ecommerce.whatFor.dataOwnershipA1')}</p>*/}
                                {/*<p>{t('solutions:ecommerce.whatFor.dataOwnershipA1Bis')}</p>*/}
                                {/*<p>{t('solutions:ecommerce.whatFor.dataOwnershipA1Tris')}</p>*/}
                                <h6>{t('solutions:ecommerce.whatFor.dataOwnershipQ2')}</h6>
                                <h6>{t('solutions:ecommerce.whatFor.dataOwnershipQ3')}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* How does it work section */}
            <div className="container pv40" style={styles.section}>
                <div className="row mb30">
                    <div className="col-12" style={{ textAlign: 'center' }}>
                        <h2 className="mb20">{t('solutions:ecommerce.howWorks.title')}</h2>
                        <h5 style={{ fontWeight: 400 }}>{t('solutions:ecommerce.howWorks.subtitle')}</h5>
                    </div>
                </div>
                <div className="row mb30">
                    <div className="col-12" style={{ textAlign: 'center' }}>
                        <img
                            src={i18n.language === 'en' ? biuwerForEcommerceHowWorksImgEn : biuwerForEcommerceHowWorksImgEs}
                            alt={t('solutions:ecommerce.howWorks.title')}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>1</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.howWorks.bigNumber1')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.howWorks.bigNumber1Text')}</p>
                    </div>
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>2</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.howWorks.bigNumber2')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.howWorks.bigNumber2Text')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>3</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.howWorks.bigNumber3')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.howWorks.bigNumber3Text')}</p>
                        <p>{t('solutions:ecommerce.howWorks.bigNumber3TextBis')}</p>
                    </div>
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>4</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.howWorks.bigNumber4')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.howWorks.bigNumber4Text')}</p>
                    </div>
                </div>
            </div>
            {/* Faqs section */}
            <section className="pv40" style={{ backgroundColor: '#F9FBFD' }}>
                <div className="container" style={styles.section}>
                    <div className="row mb30">
                        <div className="col-12" style={{ textAlign: 'center' }}>
                            <h2 className="mb20">{t('solutions:ecommerce.faqs.title')}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 mb40">
                            <FaqItem question={t('solutions:ecommerce.faqs.q1').toUpperCase()}>
                                <p>
                                    {t('solutions:ecommerce.faqs.a1')}
                                </p>
                            </FaqItem>
                            <FaqItem question={t('solutions:ecommerce.faqs.q2').toUpperCase()}>
                                <p>
                                    {t('solutions:ecommerce.faqs.a2')}
                                </p>
                            </FaqItem>
                        </div>
                        <div className="col-12 col-sm-6 mb40">
                            <FaqItem question={t('solutions:ecommerce.faqs.q3').toUpperCase()}>
                                <ul>
                                    <li>{t('solutions:ecommerce.faqs.a3')}</li>
                                    <li>{t('solutions:ecommerce.faqs.a3Bis')}</li>
                                    <li>{t('solutions:ecommerce.faqs.a3Tris')}</li>
                                    <li>{t('solutions:ecommerce.faqs.a3Cuatris')}</li>
                                </ul>
                            </FaqItem>
                            <FaqItem question={t('solutions:ecommerce.faqs.q4').toUpperCase()}>
                                <p>{t('solutions:ecommerce.faqs.a4')}</p>
                                <p>{t('solutions:ecommerce.faqs.a4Bis')}</p>
                                <p>{t('solutions:ecommerce.faqs.a4Tris')}</p>
                            </FaqItem>
                        </div>
                    </div>
                </div>
            </section>
            {/* Benefits Section */}
            <div className="container pv40" style={styles.section}>
                <div className="row mb30">
                    <div className="col-12" style={{ textAlign: 'center' }}>
                        <h2 className="mb20">{t('solutions:ecommerce.benefits.title')}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>1</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.benefits.b1Title')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.benefits.b1Text')}</p>
                    </div>
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>2</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.benefits.b2Title')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.benefits.b2Text')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>3</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.benefits.b3Title')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.benefits.b3Text')}</p>
                    </div>
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>4</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.benefits.b4Title')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.benefits.b4Text')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>5</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.benefits.b5Title')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.benefits.b5Text')}</p>
                    </div>
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div style={styles.bigNumber}>6</div>
                            <div style={styles.bigNumberTitle}>
                                {t('solutions:ecommerce.benefits.b6Title')}
                            </div>
                        </div>
                        <p>{t('solutions:ecommerce.benefits.b6Text')}</p>
                    </div>
                </div>
            </div>
            {/* Request Demo Section */}
            <div className="container pv40">
                <div className="row mb30">
                    <div className="col-12" style={{textAlign: 'center'}}>
                        <h2 style={{fontWeight: 'bold'}}>{t('solutions:ecommerce.requestDemoEcommerce.msg1')}</h2>
                        <h5>{t('solutions:ecommerce.requestDemoEcommerce.msg2')}</h5>
                    </div>
                </div>
                <div className="row mb30">
                    <div className="col-12" style={{textAlign: 'center'}}>
                        <div>
                            <Button
                                text={t("requestDemoSection.msg3").toUpperCase()}
                                intent="primary"
                                style={{boxShadow: 'none', marginTop: 15, marginRight: 20}}
                                large={true}
                                onClick={() => i18n.language === 'en' ? navigate('/en/book-demo/') : navigate('/es/reserva-demo/')}
                            />
                            <a
                                href={`https://app.biuwer.com/signup?language=${i18n.language || 'en'}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-success"
                                style={{marginTop: 15, color: 'white', textDecoration: 'none', height: 40}}
                            >
                                {t("mainMenuLinks.tryForFree").toUpperCase()}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default EcommerceSolution;