import React, { useState } from 'react';
import { Icon, Collapse } from "@blueprintjs/core";
import { grey } from "material-colors";

const FaqsItem = ({ question, answer, children }) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '20px 0'
        }}>
            <span
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    cursor: 'pointer',
                    marginBottom: 15,
                    outline: 'none'
                }}
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={() => setIsOpen(!isOpen)}
                role="button"
                tabIndex={0}
            >
                <div style={{ width: "100%", marginRight: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>{question}</span>
                </div>
                <div style={{ width: 30 }}>
                    <Icon icon={isOpen ? "chevron-up" : "chevron-down"} iconSize={20} />
                </div>
            </span>
            <Collapse isOpen={isOpen} keepChildrenMounted={true}>
                <div style={{ fontSize: 14 }}>
                    {answer ? <p style={{ fontSize: 14 }}>{answer}</p> : children}
                </div>
            </Collapse>
            <div style={{ borderBottom: `1px solid ${grey[300]}` }} />
        </div>
    );
};

export default FaqsItem;
